<script setup lang="ts">
import { computed } from 'vue'
import { propTypes } from '@/utils/propTypes'

const props = defineProps({
  iconName: propTypes.string,
  className: propTypes.string,
  color: propTypes.string.def('red'),
  size: propTypes.string.def('16px')
})

// 图标在 iconfont 中的名字
const iconClassName = computed(() => {
  return `#${props.iconName}`
})

// 给图标添加上类名
const svgClass = computed(() => {
  if (props.className) {
      return `svg-icon ${props.className}`
  }
  return 'svg-icon'
})
</script>

<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconClassName" :fill="color" />
  </svg>
</template>

<style lang="less" scoped>
.svg-icon {
  /* v-bind 是 Vue3 才支持的功能，可以将 CSS 的值与 js 的值绑定 */
  width: v-bind('props.size');
  height: v-bind('props.size');
  position: relative;
  fill: currentColor;
  vertical-align: -2px;
}
</style>
